<template>
  <span class="base-text"><slot /></span>
</template>

<style lang="scss" scoped>
.slighty-bold {
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
  line-height: 1.5rem;
}
.bold {
  font-weight: 700;
  line-height: 1.5rem;
}

.text-light {
  color: $light-100;
}

.centered {
  text-align: center;
}
.centered-block {
  display: block;
  text-align: center;
}
.text-xl {
  font-size: 4rem;
  line-height: 5rem;
}

.dark-50 {
  color: $dark-50;
}
.light-70 {
  color: $light-70;
}

.strikethrough {
  text-decoration: line-through;
}

.text-large {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.text-extra-large {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.card-title {
  color: $dark-100;
  display: block;
  text-align: center;
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.625rem;
}
.card-subtitle {
  color: $dark-50;
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 0.825rem;
  line-height: 1.125rem;
}

.blog-title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 130%;
}
</style>
