<template>
  <div>
    <NuxtLayout>
      <div class="error-page container">
        <div class="error-message">
          <BaseTitle class="error-title mb-1">
            {{ error?.statusCode || "404" }}
          </BaseTitle>
          <BaseText class="error-subtitle">
            {{ $t("error.subtitle") }}
          </BaseText>
        </div>
        <BaseButtonLink to="/" class="mt-2 mb-3">{{
          $t("error.home-button")
        }}</BaseButtonLink>
        <BaseImage :src="imageSrc" class="folder-stack" />
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object,
});

const { locale } = useI18n();
const imageSrc = computed(() => `folderstack-${locale.value}.webp`);

// Get the body class
const bodyClass = computed(() => {
  if (isEmbedded()) {
    return "embed";
  }
  return useCurrentHost().value;
});

useHead({
  bodyAttrs: { class: bodyClass.value },
  link: [
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/icon?family=Material+Icons",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/icon?family=Material+Icons+Outlined",
    },
  ],
});
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
}

.error-message {
  margin-top: auto;
  text-align: center;
  .error-title {
    font-size: 6rem;
    font-weight: 600;
  }
  .error-subtitle {
    font-size: 1.75rem;
    font-weight: 600;
  }
}

.folder-stack {
  width: auto;
  height: auto;
  max-width: 50vw;
  max-height: 50vh;
  margin-top: auto;
}
</style>
