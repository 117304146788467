import type { RouterConfig } from "@nuxt/schema";

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  /*
   * Redirects
   */
  routes: (_routes) => {
    return _routes.concat([{ path: "/nl/app", redirect: "/app" }]);
  },

  /*
   * Scroll to the same position when navigating on routes with noScroll in the query param
   */
  scrollBehavior(to, from) {
    // Don't scroll if we're staying on the same page
    if (to.path === from.path) return false;

    const nuxtApp = useNuxtApp();

    if (to.query.noScroll) {
      const router = useRouter();
      // Remove the noScroll query parameter once the page is loaded
      nuxtApp.hooks.hookOnce("page:finish", () => {
        router.replace({ query: { ...to.query, noScroll: undefined } });
      });

      return false;
    }

    // If not, return to the top of the page (after it is loaded)
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce("page:finish", () => {
        resolve({
          top: 0,
        });
      });
    });
  },
};
