/*
 * Count every route navigation, so we can use that number to prevent
 * router.back() from navigating away from the page.
 *
 * See BrochureNavbar.vue for an example.
 */
export default defineNuxtRouteMiddleware(() => {
  const routeCount = useRouteHistoryCount();
  routeCount.value++;
});
