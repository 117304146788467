export default defineAppConfig({
  // Apple app site association (handled in ./server/.well-known/apple-app-site-association.ts)
  appleAppSiteAssociation: {
    applinks: {
      apps: [],
      details: [
        {
          appID: "{APPLE_TEAM_ID}.{APP_PACKAGE}", // Replaced on the server
          paths: ["*"],
          // TODO: Make it only open the app when on the brochure page
          // paths: ["/*-aanbiedingen/*", "/nl/*-folder/*", "/fr/*-folder/*"],
        },
      ],
    },
  },
  // Android assetlinks (handled in ./server/.well-known/assetlinks.json.ts)
  assetlinks: {
    relation: ["delegate_permission/common.handle_all_urls"],
    target: {
      namespace: "android_app",
      package_name: "{APP_PACKAGE}", // Replaced on the server
      sha256_cert_fingerprints: [
        "{ANDROID_SHA256_FINGERPRINT_1}", // Replaced on the server
        "{ANDROID_SHA256_FINGERPRINT_2}", // Replaced on the server
      ],
    },
  },
  // Android native app install banner manifest.json (handled in ./server/routes/manifest.json.ts)
  manifest: {
    name: "{APP_NAME}",
    short_name: "{SHORT_NAME}",
    icons: [
      {
        src: "icons/icon-{domain}-0-75x.png",
        sizes: "36x36",
        type: "image/png",
      },
      {
        src: "icons/icon-{domain}-1x.png",
        sizes: "48x48",
        type: "image/png",
      },
      {
        src: "icons/icon-{domain}-1-5x.png",
        sizes: "72x72",
        type: "image/png",
      },
      {
        src: "icons/icon-{domain}-2x.png",
        sizes: "96x96",
        type: "image/png",
      },
      {
        src: "icons/icon-{domain}-3x.png",
        sizes: "144x144",
        type: "image/png",
      },
      {
        src: "icons/icon-{domain}-4x.png",
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: "icons/icon-{domain}-512px.png",
        sizes: "512x512",
        type: "image/png",
      },
    ],
    prefer_related_applications: true,
    related_applications: [
      {
        platform: "play",
        id: "{APP_PACKAGE}",
        url: "{APP_PLAY_STORE_URL}",
      },
    ],
    start_url: ".",
    display: "standalone",
  },
  // The ads.txt file, for google adsense, per domain
  adsTxt: {
    allefolders: "google.com, pub-7814118610095655, DIRECT, f08c47fec0942fa0",
    promopromo: "google.com, pub-9832024765354406, DIRECT, f08c47fec0942fa0",
  },
  adsJs: {
    allefolders:
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7814118610095655",
    promopromo:
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9832024765354406",
  },
  iOSAppId: {
    allefolders: "1086601848",
    promopromo: "811303467",
  },
});
