import VueGtag from "vue-gtag";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  // Get the correct GA_ID for each release level and domain
  let GA_MEASUREMENT_ID: string | null = null;
  const {
    releaseLevel,
    GaMeasurementIdAlleFoldersStaging,
    GaMeasurementIdPromoPromoStaging,
    GaMeasurementIdAlleFoldersProduction,
    GaMeasurementIdPromoPromoProduction,
  } = useRuntimeConfig().public;
  const host = useCurrentHost();
  if (releaseLevel === "production") {
    if (host.value === "allefolders") {
      GA_MEASUREMENT_ID = GaMeasurementIdAlleFoldersProduction;
    } else if (host.value === "promopromo") {
      GA_MEASUREMENT_ID = GaMeasurementIdPromoPromoProduction;
    }
  } else if (host.value === "allefolders") {
    GA_MEASUREMENT_ID = GaMeasurementIdAlleFoldersStaging;
  } else if (host.value === "promopromo") {
    GA_MEASUREMENT_ID = GaMeasurementIdPromoPromoStaging;
  }

  if (!GA_MEASUREMENT_ID) return;

  nuxtApp.vueApp.use(
    VueGtag,
    {
      config: {
        id: GA_MEASUREMENT_ID,
        params: {
          anonymize_ip: true,
        },
      },
      enabled: true,
    },
    router
  );
});
