/*
 * Composable to use dynamic files from the assets folder in Nuxt 3
 * See: https://github.com/nuxt/nuxt/issues/14766#issuecomment-1436795644
 */

const imageAssets = () =>
  useState<Record<string, unknown> | null>("image-assets", () => null);

export function useImage(path: MaybeRef<string>): Ref<string | undefined> {
  const images = imageAssets();

  if (!images.value) {
    images.value = import.meta.glob("~/assets/images/**/*", {
      eager: true,
      import: "default",
    });
  }

  const image = ref<string | undefined>(undefined);

  if (images.value) {
    image.value = images.value["/assets/images/" + toRef(path).value] as string;
  }

  return image;
}
