import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const { releaseLevel, SentryDSN } = useRuntimeConfig().public;
  const { vueApp } = nuxtApp;
  const router = useRouter();

  Sentry.init({
    app: vueApp,
    dsn: SentryDSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    allowUrls: [
      "localhost:3000",
      "staging.www.allefolders.nl",
      "allefolders.nl",
      "staging.www.promopromo.be",
      "promopromo.be",
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,

    environment: releaseLevel,
  });
});
