<template>
  <div>
    <NuxtLoadingIndicator :color="loadingIndicatorColor" />
    <NuxtLayout>
      <Suspense>
        <NuxtPage />
      </Suspense>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const host = useCurrentHost();
const { t } = useI18n();

// If the host is promopromo and the language is 'nl', force it to be 'be'
const { locale, setLocale } = useI18n();

if (host.value === "promopromo" && locale.value === "nl-NL") {
  setLocale("nl");
}

// The meta description
const description = t("meta_description");

// The title of the host (AlleFolders or PromoPromo)
const hostTitle = computed(() => t(`title-${host.value}`));
const { adsJs, iOSAppId } = useAppConfig();

// Get the body class
const bodyClass = computed(() => {
  if (isEmbedded()) {
    return "embed";
  }
  return useCurrentHost().value;
});

// ios native app install banner
const appId = ref("");
if (host.value === "promopromo") {
  appId.value = iOSAppId.promopromo;
} else if (host.value === "allefolders") {
  appId.value = iOSAppId.allefolders;
}

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${hostTitle.value}` : hostTitle.value;
  },

  htmlAttrs: {
    lang: locale.value,
  },
  link: [
    // Favicon SVG
    {
      rel: "icon",
      type: "image/svg+xml",
      href: `/favicon-${host.value}.svg`,
    },
    // Favicon fallback
    {
      rel: "alternate icon",
      type: "image/x-icon",
      href: `/favicon-${host.value}.ico`,
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/icon?family=Material+Icons",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/icon?family=Material+Icons+Outlined",
    },
    // Android native app install banner manifest.json (see: server/routes/manifest.json.ts)
    {
      rel: "manifest",
      href: "/manifest.json",
    },
  ],
  meta: [
    { name: "description", content: description },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
    {
      charset: "utf-8",
    },
    // Apple app install banner
    {
      name: "apple-itunes-app",
      content: `app-id=${appId.value}`,
    },
  ],
  bodyAttrs: { class: bodyClass.value },

  // Google Adsense
  script: [
    !isEmbedded()
      ? {
          async: true,
          src: adsJs[host.value],
          crossorigin: "anonymous",
        }
      : {},
  ],
});

useSeoMeta({
  ogTitle: hostTitle.value,
  ogDescription: description,
  ogImage: useImage(
    host.value === "promopromo"
      ? "folderstack-nl-NL.webp"
      : "folderstack-nl.webp"
  ).value,
  ogUrl: useRequestURL().toString(),
});

// The loadingindicator color based on domain
const loadingIndicatorColor =
  useCurrentHost().value === "allefolders"
    ? "repeating-linear-gradient(to right, #ff6202 0%, #ffe0cc 100%)"
    : "repeating-linear-gradient(to right, #da3064 0%, #f8d6e0 100%)";
</script>
