<template>
  <img class="base-image" :src="logo" />
</template>

<script setup lang="ts">
const props = defineProps<{ src: string }>();

const logo = useImage(toRef(props, "src"));
</script>

<style lang="scss" scoped>
.base-image {
  max-width: 100%;
  object-fit: contain;

  &.icon {
    min-width: 16px;
    min-width: 16px;
    max-height: 16px;
    max-height: 16px;
  }
}
</style>
