import {
  createClient,
  ssrExchange,
  cacheExchange,
  fetchExchange,
} from "@urql/core";
import urql, { type SSRData } from "@urql/vue";
import { type NuxtApp } from "nuxt/app";

const SSR_KEY = "__URQL_DATA__";

export default defineNuxtPlugin((nuxt) => {
  const ssr = ssrExchange({
    isClient: process.client,
  });

  // when app is created in browser, restore SSR state from nuxt payload
  if (process.client) {
    nuxt.hook("app:created", () => {
      ssr.restoreData(nuxt.payload[SSR_KEY] as SSRData);
    });
  }

  // when app has rendered in server, send SSR state to client
  if (process.server) {
    nuxt.hook("app:rendered", () => {
      nuxt.payload[SSR_KEY] = ssr.extractData();
    });
  }

  const { releaseLevel } = useRuntimeConfig().public;

  const options = {
    url:
      releaseLevel === "production"
        ? "https://api.jafolders.com/graphql"
        : "https://api.s.jafolders.com/graphql",

    fetchOptions: () => {
      const { app, language, platform, buildNumber, versionName } =
        getJaFoldersContext(nuxt.vueApp.$nuxt);
      return {
        headers: {
          "jafolders-context": `${app};${language};${platform};${buildNumber};${versionName}`,
        },
      };
    },
    exchanges: [
      cacheExchange,
      ssr, // Add `ssr` in front of the `fetchExchange`
      fetchExchange,
    ],
  };

  const client = ref(createClient(options));

  const urqlResetCache = () => {
    client.value = createClient(options);
  };

  nuxt.vueApp.use(urql, client);

  return {
    provide: {
      urqlResetCache,
    },
  };
});

interface JaFoldersContext {
  app: string;
  language: string;
  platform: "web" | "embed";
  buildNumber: string;
  versionName: string;
}

const getJaFoldersContext = (nuxt: NuxtApp): JaFoldersContext => {
  const host = useCurrentHost();
  const isEmbed = isEmbedded();
  let lang = unref(nuxt.$i18n.locale);

  if (lang === "nl-NL") {
    lang = "nl";
  }

  return {
    app: host.value,
    language: lang,
    platform: isEmbed ? "embed" : "web",
    buildNumber: "1",
    versionName: "1", // TODO get from sentry
  };
};

// TODO: Check if we need this or nuxt automatically adds it
declare module "#app" {
  interface NuxtApp {
    $urqlReset: () => void;
  }
}
