export default () => {
  const origin = useRequestURL().origin;

  return computed(() => {
    if (origin.includes("promopromo")) {
      return "promopromo";
    } else if (origin.includes("allefolders")) {
      return "allefolders";
    }

    // Fallback for localhost development
    return "allefolders";
  });
};
