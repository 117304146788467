<template>
  <nuxt-link :to="localePath(to)">
    <BaseButton v-bind="$attrs" class="base-button-link"><slot /></BaseButton>
  </nuxt-link>
</template>

<script setup lang="ts">
import BaseButton from "./BaseButton.vue";
defineOptions({
  inheritAttrs: false,
});

defineProps<{
  to: string;
}>();

const localePath = useLocalePath();
</script>

<style lang="scss" scoped>
.base-button-link {
  cursor: pointer;
}
.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
